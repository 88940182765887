<template>
  <div>
    <v-container
      grid-list-xl>
      <v-layout
        fluid
        pt-0
        wrap>
        <v-flex xs12>
          <app-card
            :footer="true"
            heading="Post information"
            col-classes="xl12 lg12 md12 sm12 xs12"
            custom-classes="mb-0"
          >
            <div>
              <rotate-square2 v-if="loading" />
              <v-container
                v-else
                grid-list-xl>
                <v-layout
                  row
                  wrap>
                  <v-flex
                    xs12
                    sm6>
                    <v-select
                      :items="typeList"
                      v-model="post.type"
                      :rules="[v => Boolean(v) || 'Please select a type']"
                      label="Type"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    sm6>
                    <v-select
                      :items="languageList"
                      v-model="post.language"
                      :rules="[v => Boolean(v) || 'Please select a language']"
                      label="Language"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    sm6>
                    <v-text-field
                      v-model="post.title"
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      :rules="[v => Boolean(v) || 'Please provide a title']"
                      label="Title"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    sm6>
                    <InputHotKeyWrapper>
                      <v-text-field
                        v-model="post.weight"
                        :max-length="TEXT_FIELD_MAX_LENGTH"
                        :rules="[v => Boolean(v) || 'Please provide a weight']"
                        type="number"
                        label="Weight"
                      />
                    </InputHotKeyWrapper>
                  </v-flex>
                  <v-flex
                    xs12
                    sm6>
                    <v-select
                      v-if="brandList.length"
                      :items="brandList"
                      :rules="[v => v.length>0 || 'Please select at least 1 brand']"
                      v-model="post.brands"
                      :item-text="(val) => { return val.name }"
                      clearable
                      item-value="id"
                      multiple
                      label="Brands"
                    />
                  </v-flex>
                  <v-flex
                    xs12>
                    <v-textarea
                      v-model="post.body"
                      :rules="[v => Boolean(v) || 'Please provide a weight']"
                      label="Body"
                    />
                  </v-flex>
                </v-layout>
              </v-container>
            </div>
            <template slot="footer">
              <v-btn
                color="primary"
                class="mr-3"
                @click="saveFunction()"
              >Save</v-btn>
            </template>
          </app-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import Request from '../../../../helpers/request'
import Brands from '../../../../helpers/brands'
import { TEXT_FIELD_MAX_LENGTH, POST_TYPES, LANGUAGE_TYPES } from '../../../../constants/common'

export default {
  mixins: [Request, Brands],
  data () {
    return {
      brandList: [],
      typeList: POST_TYPES,
      languageList: LANGUAGE_TYPES,
      TEXT_FIELD_MAX_LENGTH,
      loading: true,
      post: {}
    }
  },
  async beforeMount () {
    this.requestData()

    try {
      this.brandList = await this.fetchBrands()
    } catch (e) {
      throw new Error(e)
    }
  },
  methods: {
    requestData () {
      this.request('GET', `/posts/${this.$route.params.id}`, {}, ({ data }) => {
        this.post = data
      }, (loader) => { this.loading = loader })
    },
    saveFunction () {
      let body = {
        data: {
          title: this.post.title,
          body: this.post.body,
          weight: this.post.weight,
          type: this.post.type,
          language: this.post.language,
          brands: this.post.brands.map(brand => brand.id || brand)
        }
      }

      this.request('PATCH', `/posts/${this.$route.params.id}`, body, ({ data }) => {
        this.$notify({
          group: 'topRight',
          type: 'success',
          text: 'Post is updated'
        })

        this.$router.push('/tables/posts')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
